@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --creating-animation-duration: 120s;
  --training-animation-duration: 3600s;
  --training-animation-width: 0;
}

html,
body,
#root {
  height: 100dvh;
  margin: 0;
  padding: 0;
  @apply bg-black;
}

@layer utilities {
  .text-h1 {
    @apply text-head1 font-workSans;
  }

  .text-h2 {
    @apply text-head2 font-workSans;
  }

  .text-h3 {
    @apply text-head3 font-workSans;
  }

  .text-h4 {
    @apply text-head4 font-workSans;
  }

  .text-h5 {
    @apply text-head5 font-workSans;
  }

  .text-h6 {
    @apply text-head6 font-workSans;
  }

  .text-h7 {
    @apply text-head7 font-workSans;
  }

  .text-h7-satoshi {
    font-family: "Satoshi";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 28.8px */
    letter-spacing: -0.48px;
  }

  .text-bodyLg {
    @apply text-bodyLarge font-workSans;
  }

  .text-bodyMd {
    @apply text-bodyMedium font-workSans;
  }

  .text-bodySm {
    @apply text-bodySmall font-workSans;
  }

  .text-headline {
    @apply text-headline1 font-workSans;
  }

  .text-menu {
    @apply font-workSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.21px;
  }

  .text-overflow {
    @apply whitespace-nowrap text-ellipsis overflow-hidden flex-1;
  }

  .text-transparent {
    color: transparent;
  }

  .border-transparent {
    border-color: transparent;
  }

  .container-fluid {
    @apply w-full px-5 lg:px-[65px] 2xl:mx-auto 2xl:max-w-[1980px];
  }

  .container-fluid-header {
    @apply w-full px-5 2xl:mx-auto 2xl:max-w-[1980px];
  }

  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .btn-primary-animation {
    @apply transition-all hover:bg-purple;
  }

  .btn-secondary-animation {
    @apply transition-all hover:bg-pink;
  }

  .btn-warning-animation {
    @apply transition-all hover:bg-orange-light;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }
  .items-normal {
    align-items: normal !important;
  }
}

@layer components {
  :root {
    --th-black: 255 255 255;
    --th-black-100: 250 250 250; /* #FAFAFA */ /* Grey 100 */
    --th-black-200: 234 234 234; /* #EAEAEA */ /* Grey 200 */
    --th-black-300: 228 228 228; /* #E4E4E4 */ /* Grey 300 */
    --th-grey: 110 110 110; /* #6E6E6E */ /* Grey 400 */
    --th-white: 0 0 0;

    --th-black-green: 0 0 0;

    --true-black: 0 0 0;
    --true-white: 255 255 255;
  }

  .dark:root {
    --th-black: 0 0 0;
    --th-black-100: 20 20 20; /* #141414 */ /* Black 100 */
    --th-black-200: 28 28 28; /* #1C1C1C */ /* Black 200 */
    --th-black-300: 43 43 43; /* #2B2B2B */ /* Black 300 */
    --th-grey: 147 147 147; /* #939393 */ /* Grey */
    --th-white: 255 255 255;

    --th-black-green: 209 239 112;
  }

  .accordion-element {
    max-height: 0;
    transition: max-height 0.2s ease-out, padding 0.2s ease-out, margin 0.2s ease-out, border 0.2s ease-out;
  }

  .select-item {
    @apply cursor-pointer border border-black-300 rounded-[3px] px-2.5 py-3;
    @apply text-bodySm text-white;
    @apply hover:text-green;
  }
  .select-item.active {
    @apply text-green;
    @apply bg-green bg-opacity-10;
  }
  .custom-line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .custom-line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .custom-line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .overlay::before {
    position: absolute;
    display: block;
    transform: translateX(-50%);
    left: 50%;
    width: 101%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    content: "";
    border-radius: 3px;
  }

  .btn {
    @apply flex h-10 items-center justify-center;
    @apply gap-[5px] px-2.5;
    @apply text-h6 rounded-md;
  }

  .btn.btn-primary {
    @apply bg-green;
    @apply hover:bg-purple;
    @apply disabled:!bg-black-300 disabled:cursor-default disabled:pointer-events-none disabled:text-grey;
  }

  .btn-big {
    @apply text-h5 h-12 px-5;
  }
  .btn-small {
    @apply !text-h7 !h-9 !p-2;
  }

  .btn-primary-big {
    @apply btn-primary;
    @apply btn-big;
  }
  .btn-primary-small {
    @apply btn-primary;
    @apply btn-small;
  }
  .btn.btn-secondary {
    background: transparent;
    @apply text-white border border-black-300;
    @apply hover:bg-black-300;
    @apply disabled:cursor-default disabled:pointer-events-none disabled:text-grey;
  }

  .btn-secondary-small {
    @apply btn-secondary;
    @apply btn-small;
  }

  .btn.btn-warning {
    @apply bg-orange;
    @apply hover:bg-trueWhite;
  }
  .btn-warning-small {
    @apply btn-warning;
    @apply btn-small;
  }

  .btn.btn-dash-small {
    @apply border border-dashed border-black-300;
    @apply btn-small;
    @apply text-white;
    @apply hover:border-none hover:bg-black-300;
  }

  .btn.no-bg {
    background-color: transparent;
  }

  .tab {
    @apply w-full;
    @apply flex items-center gap-[5px];
  }

  .tab li {
    @apply block flex-1;
  }

  .tab li span {
    @apply block text-h7 cursor-pointer text-grey text-center;
    @apply py-1.5 px-2.5;
    @apply whitespace-nowrap;
    @apply bg-black-300;
    @apply rounded-[4px];
    @apply w-full;
  }

  .tab.tab2 li span {
    @apply rounded-none;
    background-color: transparent;
  }

  .tab.tab3 li span {
    @apply rounded-[3px];
    @apply !p-0;
    background-color: transparent;
  }

  .tab li span:hover {
    @apply text-trueBlack dark:text-green;
  }

  .tab li span.active {
    @apply text-trueBlack;
    @apply bg-green;
  }

  .tab.tab2 li span.active {
    @apply relative;
    @apply text-blackGreen;
    background-color: transparent;
  }

  .tab.tab3 li span.active {
    @apply relative;
    @apply text-white;
    @apply bg-black-300;
  }

  /* for bottom border */
  .tab.tab2 li span.active::after {
    position: absolute;
    top: calc(100% + 6px); /* 6px may change  */
    left: 0;
    content: "";
    @apply bg-blackGreen;
    @apply h-[1px] w-full;
  }

  .tab.tab3 li span.active::after {
  }

  .tab.icon {
    background: transparent;
    @apply inline-flex border border-border rounded-sm;
  }

  .tab.icon.vertical {
    @apply block;
  }

  .tab.icon li {
    @apply border-l border-l-border border-r-0 first:border-none text-black;
  }

  .tab.icon li span {
    background: transparent;
    @apply p-2 text-grey rounded-none;
  }

  .tab.icon li span:hover {
    background: initial;
    @apply text-white;
  }

  .tab.icon li span.active {
    @apply text-black-200 bg-white rounded-sm after:hidden;
  }

  .tab.icon.link li span.active {
    background: initial;
    @apply text-grey;
  }

  .icon-btn {
    @apply h-10 py-4 px-2.5 rounded-2xl cursor-pointer gap-2.5;
  }

  .tab-button {
    @apply flex items-center text-center gap-x-0.5 px-2 pt-2.5 pb-3 rounded text-h6 text-black-200 bg-white;
  }

  .tab-button.secondary {
    margin: -1px 0;
    @apply text-white;
    @apply px-8 py-7;
    @apply bg-black border border-border border-b-white rounded-none;
  }

  .input {
    background: transparent;
    @apply w-full text-white outline-none text-bodySm placeholder-grey;
  }

  .input[type="number"]::-webkit-outer-spin-button,
  .input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .input[type="number"] {
    -moz-appearance: textfield;
  }

  .auth-input {
    @apply !text-black;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff !important; /* Explicitly set to white */
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: 0 0 0px 1000px transparent inset;
  }

  .auth-input:-webkit-autofill,
  .auth-input:-webkit-autofill:hover,
  .auth-input:-webkit-autofill:focus,
  .auth-input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #000000 !important; /* Explicitly set to black */
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: 0 0 0px 1000px transparent inset;
  }

  .input:focus {
    background: transparent;
  }
  .input:disabled {
    @apply text-grey;
  }

  .input-container:has(.input:focus) {
    @apply bg-black;
  }

  .input-container:has(.auth-input:focus) {
    background-color: transparent !important;
  }

  .input-container:has(input:not(:placeholder-shown)) {
    @apply bg-black;
  }

  .input-container:has(.auth-input:not(:placeholder-shown)) {
    background-color: transparent !important;
  }

  .input-container:has(textarea:not(:placeholder-shown)) {
    @apply bg-black;
  }

  .animated-border:has(.input-container) .inactive {
    @apply border border-black-300;
  }

  .animated-border:not(.inactive) .input-container {
    @apply border-none;
  }

  @keyframes snackbarAnimation {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  .animate-snackbar {
    animation: snackbarAnimation 3s linear;
  }

  .slideshow-container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .slideshow {
    display: flex;
    transition: transform 0.5s ease; /* Add a smooth transition effect */
  }

  .slideshow img {
    width: 100%;
    height: auto;
  }

  @keyframes progressAnimation {
    0% {
      width: 0;
    }
    100% {
      width: 99%;
    }
  }

  @keyframes progressAnimation2 {
    0% {
      width: var(--training-animation-width);
    }
    100% {
      width: 100%;
    }
  }

  .animate-design-progress {
    animation: progressAnimation var(--creating-animation-duration) forwards;
  }

  .animate-training-progress {
    animation: progressAnimation2 var(--training-animation-duration) forwards;
  }

  .animate-image-upload {
    animation: progressAnimation 10s forwards;
  }

  .dot-flashing {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    @apply text-border bg-border;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.35s;
  }
  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -12px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    @apply text-border bg-border;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 12px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    @apply text-border bg-border;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0.7s;
  }

  @keyframes dot-flashing {
    0% {
      @apply bg-white;
    }
    50%,
    100% {
      @apply bg-border;
    }
  }

  .skeleton-box {
    position: relative;
    overflow: hidden;
  }

  .skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, transparent 0%, rgba(71, 71, 71, 0.4) 50%, transparent 100%);
    animation: shimmer 2s infinite; /* Adjust the animation speed */
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  .toast {
    background: #252525 !important;
  }

  .toast-process {
    top: 0;
    background: white !important;
    height: 2px !important;
  }

  .upload-box-small .error-text {
    @apply text-[9px];
  }

  .upload-box-small .default-child {
    @apply !p-0;
  }

  .upload-box-small .default-child.error .upload-icon {
    display: none !important;
  }

  .upload-box-small .default-child.error .icon-warning {
    @apply hidden;
  }
}

.sketch-picker input {
  @apply !w-full !bg-black-100 !rounded-lg !text-white;
  @apply !text-bodySmall;
  border: 1px solid #2b2b2b !important;
  outline: none !important;
  box-shadow: none !important;
}

.sketch-picker .flexbox-fix {
  border: none !important;
}

.sketch-picker span {
  @apply !text-grey;
}

.list-disc {
  ::marker {
    @apply !text-purple dark:!text-green !w-1.5;
  }
}

.w-color-editable-input {
  align-items: center !important;
}

.w-color-editable-input input {
  border: 1px solid #2b2b2b !important;
  box-shadow: none !important;
  border-radius: 5px;
  outline: none !important;
}

.ai-border {
  border: 1px solid transparent;
  border-image: linear-gradient(to top, #d1ef70, #2b2b2b) 1;
  border-radius: 5px;
}

.bg-size-200 {
  background-size: 100% 150%;
}

.bg-position-initial {
  background-position: 0% 0%;
}

.bg-border-animation {
  animation: borderAnimation 2s ease-in-out infinite alternate;
}

@keyframes borderAnimation {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.w-color-editable-input {
  align-items: center !important;
}

.w-color-editable-input input {
  border: 1px solid #2b2b2b !important;
  box-shadow: none !important;
  border-radius: 5px;
  outline: none !important;
}
