.tooltip {
  @apply relative;
  z-index: 9999;
}

.tooltip:hover .content {
  visibility: visible;
  opacity: 1;
}

.tooltip .content {
  visibility: hidden;
  @apply w-max;
  @apply opacity-0;
  @apply transform ease-in duration-75;
  @apply mt-1;
  @apply absolute top-full left-1/2 -translate-x-1/2;
  @apply bg-black rounded-[5px];
  @apply border border-black-300;
  @apply p-2;
  @apply text-center;
  @apply text-headline text-white;
}

.tooltip .content .arrow {
  content: "";
  height: 11px;
  width: 11px;
  @apply absolute top-1/2 left-0 -translate-y-1/2 -translate-x-1/2 rotate-45;
  @apply bg-black border border-grey;
  z-index: 0;
}

.tooltip .content .arrow:after {
  content: "";
  display: inline-block;
  width: 11px;
  height: 11px;
  top: -1px;
  left: -1px;
  border-color: transparent;
  @apply absolute;
  @apply border;
}

.tooltip.top .content {
  top: initial;
  margin-top: initial;
  @apply bottom-full mb-1;
}

.tooltip.top .content .arrow {
  @apply top-full;
}

.tooltip.top .content .arrow:after {
  border-color: transparent;
}

.tooltip.left .content {
  @apply mt-0;
  @apply mr-1;
  @apply top-0;
  @apply right-full;
  left: initial;
  @apply transform-none;
}

.tooltip.left .content .arrow {
  @apply top-0;
  @apply left-full;
}

.tooltip.left .content .arrow:after {
  border-color: transparent;
}

.tooltip.right .content {
  @apply mt-0;
  @apply ml-1;
  @apply top-0;
  @apply left-full;
  right: initial;
  @apply transform-none;
}

.tooltip.right .content .arrow {
  @apply top-0;
  @apply right-full;
}

.tooltip.right .content .arrow:after {
  border-color: transparent;
}
